import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import logger_45global from "/usr/src/app/middleware/logger.global.ts";
import route_45global from "/usr/src/app/middleware/route.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  logger_45global,
  route_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}