import { defineNuxtPlugin } from '#app';
import type { DocuSignInstance } from '~/types/docusign';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const loadDocuSign = () => {
    return new Promise<DocuSignInstance>((resolve, reject) => {
      if (window.DocuSign) {
        window.DocuSign.loadDocuSign(config.docusignIntegrationKey)
          .then((docusign) => {
            resolve(docusign);
          })
          .catch((error) => {
            console.error('Error loading DocuSign:', error);
            reject(error);
          });
      } else {
        const interval = setInterval(() => {
          if (window.DocuSign) {
            clearInterval(interval);
            window.DocuSign.loadDocuSign(config.docusignIntegrationKey)
              .then((docusign) => {
                resolve(docusign);
              })
              .catch((error) => {
                console.error('Error loading DocuSign:', error);
                reject(error);
              });
          }
        }, 100);
      }
    });
  };

  loadDocuSign().then((docusign) => {
    nuxtApp.provide('docusign', docusign);
  }).catch((error) => {
    console.error('Failed to initialize DocuSign:', error);
  });
});
