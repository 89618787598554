import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/usr/src/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_1UohGbtF8v from "/usr/src/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import axios_QMFgzss1s4 from "/usr/src/app/plugins/axios.ts";
import bugsnag_client_TtQ636hzGt from "/usr/src/app/plugins/bugsnag.client.ts";
import docusign_client_kPjZQUFVtl from "/usr/src/app/plugins/docusign.client.ts";
import error_handler_kEP5FliEXj from "/usr/src/app/plugins/error-handler.ts";
import google_recaptcha_rYK9Kziv68 from "/usr/src/app/plugins/google-recaptcha.ts";
import hubspot_faxzxP5z7u from "/usr/src/app/plugins/hubspot.ts";
import jsonforms_CpVIiFy31b from "/usr/src/app/plugins/jsonforms.ts";
import maska_UHaKf2z1iQ from "/usr/src/app/plugins/maska.ts";
import vuetify_7h9QAQEssH from "/usr/src/app/plugins/vuetify.ts";
import wow_client_ZbR6GH6nKG from "/usr/src/app/plugins/wow.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  plugin_1UohGbtF8v,
  axios_QMFgzss1s4,
  bugsnag_client_TtQ636hzGt,
  docusign_client_kPjZQUFVtl,
  error_handler_kEP5FliEXj,
  google_recaptcha_rYK9Kziv68,
  hubspot_faxzxP5z7u,
  jsonforms_CpVIiFy31b,
  maska_UHaKf2z1iQ,
  vuetify_7h9QAQEssH,
  wow_client_ZbR6GH6nKG
]