import { JsonForms } from '@jsonforms/vue'
import { vuetifyRenderers } from '@jsonforms/vue-vuetify';
import VBtnToggleControl from '~/components/elements/VBtnToggleControl.vue';

const customRenderers = [
  ...vuetifyRenderers,
  {
    tester: (uischema: any) => (uischema.type === 'Control' && uischema.scope.endsWith('VBtnToggleControl')) ? 10 : -1,
    renderer: VBtnToggleControl
  }
]

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('JsonForms', JsonForms);
  nuxtApp.vueApp.provide('jsonformsRenderers', customRenderers)
});