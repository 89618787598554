<template>
  <v-btn-toggle v-model="currentValue" mandatory>
    <v-btn value="Yes">Yes</v-btn>
    <v-btn value="No">No</v-btn>
  </v-btn-toggle>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'


//const props = defineProps(['uischema', 'schema', 'path'])
const props = defineProps(['control'])
const controlData = computed(() => props.control.data)
const controlPath = computed(() => props.control.path)
const label = computed(() => props.control.uischema.label)
const description = computed(() => props.control.uischema.description)

//const { data, handleChange, errors } = useJsonFormsControl(props)

//const label = computed(() => props.uischema.label)
//const description = computed(() => props.uischema.description)


const handleChange = (path: any, newValue: any) => {
  // Update the data value
  props.control.handleChange(newValue)
}

const currentValue = computed({
  get: () => controlData.value,
  set: (newValue: any) => handleChange(controlPath.value, newValue)
});
</script>

<style scoped>
.v-btn {
  margin: 5px;
}
</style>

