<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout :name="instanceLayoutName">
      <v-app>
        <NuxtPage />
      </v-app>
    </NuxtLayout>
  </div>
</template>

<script setup>
const config = useRuntimeConfig()
const instanceLayoutName = config.public.instanceName
</script>
