
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexsUGlJRzJ3ZMeta } from "/usr/src/app/pages/about/index.vue?macro=true";
import { default as indexU0pKTPb09wMeta } from "/usr/src/app/pages/contacts/index.vue?macro=true";
import { default as indexCIqh2vSS2DMeta } from "/usr/src/app/pages/cookies/index.vue?macro=true";
import { default as indexjH3R0qzS44Meta } from "/usr/src/app/pages/faqs/index.vue?macro=true";
import { default as _91_91id_93_93K9HP6Ecc1LMeta } from "/usr/src/app/pages/forms/application/[[id]].vue?macro=true";
import { default as _91_91id_93_93XnWJuAvFd5Meta } from "/usr/src/app/pages/forms/payment/[[id]].vue?macro=true";
import { default as _91_91id_93_93Bx7R001xkqMeta } from "/usr/src/app/pages/forms/quote/[[id]].vue?macro=true";
import { default as _91_91id_93_93tUfh1Rc3NBMeta } from "/usr/src/app/pages/forms/sign/[[id]].vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as indexyQxf1l2WMZMeta } from "/usr/src/app/pages/privacy/index.vue?macro=true";
import { default as _91_91id_93_93e9VmeVFMCKMeta } from "/usr/src/app/pages/ref/[[id]].vue?macro=true";
import { default as indextBqxEF1LAqMeta } from "/usr/src/app/pages/terms/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/usr/src/app/pages/about/index.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/usr/src/app/pages/contacts/index.vue")
  },
  {
    name: "cookies",
    path: "/cookies",
    component: () => import("/usr/src/app/pages/cookies/index.vue")
  },
  {
    name: "faqs",
    path: "/faqs",
    component: () => import("/usr/src/app/pages/faqs/index.vue")
  },
  {
    name: "forms-application-id",
    path: "/forms/application/:id?",
    component: () => import("/usr/src/app/pages/forms/application/[[id]].vue")
  },
  {
    name: "forms-payment-id",
    path: "/forms/payment/:id?",
    component: () => import("/usr/src/app/pages/forms/payment/[[id]].vue")
  },
  {
    name: "forms-quote-id",
    path: "/forms/quote/:id?",
    component: () => import("/usr/src/app/pages/forms/quote/[[id]].vue")
  },
  {
    name: "forms-sign-id",
    path: "/forms/sign/:id?",
    component: () => import("/usr/src/app/pages/forms/sign/[[id]].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/usr/src/app/pages/privacy/index.vue")
  },
  {
    name: "ref-id",
    path: "/ref/:id?",
    component: () => import("/usr/src/app/pages/ref/[[id]].vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/usr/src/app/pages/terms/index.vue")
  }
]