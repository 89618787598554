export default defineNuxtRouteMiddleware((to, from) => {
  useNuxtApp().hook("page:finish", () => {
    if (history?.state?.scroll) {
      setTimeout(() => {
        document?.body?.scrollTo(history?.state?.scroll);
      }, 0);
    } else {
      setTimeout(() => {
        document?.body?.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }, 0);
    }
  });
})