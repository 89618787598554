import { defineAsyncComponent } from 'vue'
export default {
  default: defineAsyncComponent(() => import("/usr/src/app/layouts/default.vue").then(m => m.default || m)),
  "emerge-footer-2": defineAsyncComponent(() => import("/usr/src/app/layouts/emerge/footer-2.vue").then(m => m.default || m)),
  emerge: defineAsyncComponent(() => import("/usr/src/app/layouts/emerge/index.vue").then(m => m.default || m)),
  "emerge-no-header-footer": defineAsyncComponent(() => import("/usr/src/app/layouts/emerge/no-header-footer.vue").then(m => m.default || m)),
  "footer-2": defineAsyncComponent(() => import("/usr/src/app/layouts/footer-2.vue").then(m => m.default || m)),
  "hilltop-footer-2": defineAsyncComponent(() => import("/usr/src/app/layouts/hilltop/footer-2.vue").then(m => m.default || m)),
  hilltop: defineAsyncComponent(() => import("/usr/src/app/layouts/hilltop/index.vue").then(m => m.default || m)),
  "hilltop-no-header-footer": defineAsyncComponent(() => import("/usr/src/app/layouts/hilltop/no-header-footer.vue").then(m => m.default || m)),
  "no-header-footer": defineAsyncComponent(() => import("/usr/src/app/layouts/no-header-footer.vue").then(m => m.default || m)),
  "pickleball-footer-2": defineAsyncComponent(() => import("/usr/src/app/layouts/pickleball/footer-2.vue").then(m => m.default || m)),
  pickleball: defineAsyncComponent(() => import("/usr/src/app/layouts/pickleball/index.vue").then(m => m.default || m)),
  "pickleball-no-header-footer": defineAsyncComponent(() => import("/usr/src/app/layouts/pickleball/no-header-footer.vue").then(m => m.default || m))
}