export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  if (process.client) {
    // Insert the HubSpot tracking code snippet here
    (function(d, s, i, r) {
      if (d.getElementById(i)){return;}
      let n = d.createElement(s) as any, e = d.getElementsByTagName(s)[0];
      n.id=i;n.src=`https://js.hs-scripts.com/${config.public.hubspotTrackingId}.js`;
      e.parentNode?.insertBefore(n, e);
    })(document, "script", "hs-script-loader");
  }
});