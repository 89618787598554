import Bugsnag from '@bugsnag/js';
import BugsnagPerformance from '@bugsnag/browser-performance'

import { useRuntimeConfig } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  
  if (config.public.bugsnagKey) {
    Bugsnag.start({
      apiKey: config.public.bugsnagKey,
      releaseStage: `${config.public.instanceName}-${config.public.vueAppEnv || 'local'}`,
      appVersion: '1.0.1',
      context: config.public.vueAppEnv,
    });

    BugsnagPerformance.start({ apiKey: config.public.bugsnagKey })
  }

  nuxtApp.provide('bugsnag', Bugsnag);
});
