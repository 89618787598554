import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { VBtn } from 'vuetify/components'
import { VDateInput } from 'vuetify/labs/VDateInput'
import DayJsAdapter from '@date-io/dayjs'
import * as directives from 'vuetify/directives'



const defaultTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    'surface-bright': '#FFFFFF',
    'surface-light': '#EEEEEE',
    'surface-variant': '#424242',
    'on-surface-variant': '#EEEEEE',
    primary: '#4FE3C1',
    secondary: '#eeff58',
    'primary-darken-1': '#13a785',
    'primary-lighten-1': '#67eece',
    'secondary-lighten-1': '#f1fc8e',
    'secondary-lighten-2': '#f4fcaa',
    'secondary-darken-1': '#aabb10',
    accent: '#9c27b0',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00'
  },
  variables: {
    'border-color': '#000000',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.60,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-kbd': '#212529',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#F5F5F5',
    'theme-on-code': '#000000'
  }
}

const hilltopTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    'surface-bright': '#FFFFFF',
    'surface-light': '#EEEEEE',
    'surface-variant': '#424242',
    'on-surface-variant': '#EEEEEE',
    primary: '#bf2e1a',
    secondary: '#1d3d7c',
    'primary-darken-1': '#861607',
    'primary-lighten-1': '#e63b24',
    'secondary-darken-1': '#0d2350',
    'secondary-lighten-1': '#1e51b9',
    'secondary-lighten-2': '#2f6ade',
    accent: '#686868',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00'
  },
  variables: {
    'border-color': '#000000',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.60,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-kbd': '#212529',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#F5F5F5',
    'theme-on-code': '#000000'
  }
}


export default defineNuxtPlugin((app) => {
  const defaultThemeName = ref('defaultTheme');
  const instanceLayoutName = useRuntimeConfig().public.instanceName
  if(instanceLayoutName === 'hilltop') {
    defaultThemeName.value = 'hilltopTheme';
  }

  const vuetify = createVuetify({
    // ... your configuration
    ssr: true,
    components: {
      VDateInput,
    },
    directives,
    date: {
      adapter: DayJsAdapter,
    },
    theme: {
      defaultTheme: defaultThemeName.value,
      themes: {
        defaultTheme,
        hilltopTheme
      }
    },
    aliases: {
      VBtnSecondary: VBtn,
      VBtnTertiary: VBtn
    },
    defaults: {
      VBtn: {
        color: 'primary',
        variant: 'flat'
      },
      VBtnSecondary: {
        color: 'primary',
        variant: 'outlined'
      },
      VBtnTertiary: {
        rounded: true,
        variant: 'plain'
      }
    }
  })
  app.vueApp.use(vuetify)
})
